document.addEventListener("alpine:init", () => {
	Alpine.data("tooltip", ({ fullWidth }) => ({
		props: { fullWidth },
		showTooltip: false,
		visible: false,
		async openTooltip() {
			if (this.visible) {
				await this.closeTooltip()
			} else {
				this.showTooltip = true
				await this.$nextTick()

				if (this.props.fullWidth) {
					const tooltipBody = this.$refs.tooltiopBodyRef

					if (!tooltipBody) return

					tooltipBody.style.cssText = `width: ${this.$refs.oppener.offsetWidth}px;`
				}
				this.visible = true
			}
		},
		async closeTooltip() {
			this.visible = false
			await new Promise((f) => setTimeout(f, 50))
			this.showTooltip = false
		},
	}))
})
